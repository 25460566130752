@font-face {
  /* regular */
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.otf") format("opentype"), /* Open Type Font */
  url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.svg") format("svg"), /* Legacy iOS */
  url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.woff") format("woff"), /* Modern Browsers */
  url("./fonts/AvenirNext/AvenirNext-Regular/AvenirNext-Regular-08.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  /* italic */
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.otf") format("opentype"), /* Open Type Font */
  url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.svg") format("svg"), /* Legacy iOS */
  url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.woff") format("woff"), /* Modern Browsers */
  url("./fonts/AvenirNext/AvenirNext-Italic/AvenirNext-Italic-05.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  /* bold */
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.otf") format("opentype"), /* Open Type Font */
  url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.svg") format("svg"), /* Legacy iOS */
  url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.woff") format("woff"), /* Modern Browsers */
  url("./fonts/AvenirNext/AvenirNext-Bold/AvenirNext-Bold-01.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  /* bold italic */
  font-family: "AvenirNext";
  src: url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.otf") format("opentype"), /* Open Type Font */
  url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.svg") format("svg"), /* Legacy iOS */
  url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.woff") format("woff"), /* Modern Browsers */
  url("./fonts/AvenirNext/AvenirNext-BoldItalic/AvenirNext-BoldItalic-02.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: bold;
  font-style: italic;
}


/* vertical line for scroll */
.vertical-line {
  width: 1px;
  height: 100%;
}

/* dont highlight on click */
.dont-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bg-height {
  height: 100%;
}

.menu-height {
  position: fixed;
  /* top: 0; */
}

@media only screen and (max-height: 850px) {
  .menu-height {
    position: sticky;
    top: 20px;
  }
  .bg-height {
    height: auto;
  }
} 

html, body {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100vw;
}

.modified-w-screen {
  min-width: calc(100vw - 40px);
} 

#root {
  height: 100%;
}

html::after {
  right: 0;
}

html::before {
  left: 0;
}

html::after, html::before {
  content: "";
  will-change: top left;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  height: 100%;
  width: 20px;
  background: #fff;
  z-index: 21000;
}

body {
  padding: 20px;
}

body::before {
  top: 0;
}

body::after {
  bottom: 0;
}

/* body {
  https://www.colorhexa.com/2a4d69 // blue
  https://www.colorhexa.com/b19cd9 // purple
  https://www.colorhexa.com/f5fcf5 // green
  https://www.colorhexa.com/c23b22 // red
  https://www.colorhexa.com/836953 // brown
  background-image: radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% );
  background-color: #eaf1f7;
  color: #1f384d;
} */

body::after, body::before {
  content: "";
  will-change: top left;
  backface-visibility: hidden;
  position: fixed;
  left: 0;
  width: 100%;
  height: 20px;
  background: #fff;
  z-index: 20000;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.screen-vh {
  margin-top: 5vh;
}

.scroll-bar-height {
  height: 50vh;
  margin-bottom: 10vh;
}

@media only screen and (min-width: 768px) {
  .screen-vh {
    margin-top: 25vh;
  }
}

@media only screen and (min-width: 1024px) {
  .screen-vh {
    margin-top: 28vh;
  }
}

@media only screen and (min-width: 1440px) {
  .screen-vh {
    margin-top: 33vh;
  }
}